#itt-sl-ucl, #root, #sl-app {
  .grid {
    word-spacing: normal;
    letter-spacing: normal;
  }

  .swiper .outOfOrder {
    display: block;
    position: relative;
    width: 100.0%;
    min-width: 25em;
    min-height: 20em;
    padding: 0 2.5em;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;

    p {
      line-height: 1.4;
    }
  }

  .cat .title {
    padding-bottom: 0.25em;

    .categoryName {
      display: block;
      width: calc(100% - 3em);
      color: var(--txt-dark);
      font-family: 'ucl-custom-bold', 'sofo-bold', Courier, monospace;
    }

    .categoryCount {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: scale(0.80);
      transform-origin: right bottom;
      opacity: 0.50;
      border-color: var(--border);
      height: calc(100% + 0.25em);
    }

    .oem-note {
      line-height: normal;
    }
  }
}