#itt-sl-ucl, #root {

  .Toastify {
    transition: all 300ms ease-in-out 0ms;
  }

  .Toastify__toast-container.overlay {
    z-index: 10000;
    transform: scale(1);
    transition: zoom;

    .toastBody {
      border-radius: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    .hpm-onlyPrint {
      display: none !important;
    }

    @media only print {
      &#root, &#itt-sl-ucl {
        position: relative;
        padding: 5rem;
        width: calc(100% - 10rem);
      }

      .hpm-onlyPrint {
        display: block !important;
      }
      .hpm-noPrint {
        display: none !important;
        max-height: 0;
        height: 0;
      }
    }
  }

}