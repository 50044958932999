#itt-sl-ucl, #root, #sl-app {

  .submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--sofa);
    color: white;
    position: absolute;
    right: 0;
    aspect-ratio: 1/1;
    z-index: 2;
    background-size: 2.00em;
    text-decoration: none;
    cursor: pointer;
  }


}