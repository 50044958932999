#itt-sl-ucl, #root, #sl-app {


  /* --- Einstellung Bilder nach Format Vorlagen + Qualität --- */
  .pro {
    aspect-ratio: 1 / 1.6025;
  }

  /* 1.6025 - 1.65 possible */

  .pro .img {
    aspect-ratio: 1 / 1;
  }

  .cat .img {
    aspect-ratio: 16 / 9;
  }

  .gal .img {
    aspect-ratio: 1 / 1;
  }

  .swiper-container.swiper-tip {
    width: 15.00em;
  }

  .swiper-container.swiper-cat {
    width: 20.00em;
  }

  .swiper-container.swiper-list {
    width: 30.00em;
  }

  .swiper-container.swiper-ref {
    width: 15.00em;
  }

  .detail .contain {
    grid-template-columns: var(--ucl-product-detail-grid, 38.0%) auto;
  }

  .tip > .claim {
    background-color: var(--bg-dark);
  }

  .tip > .claim > span {
    color: white;
  }

  /*	=================================
          Style
      ================================= */
  --custom-top-distance: var(--module-margin-top, 2em);
  --sofa: var(--highlight, #0acbaa); /* (#ff00ff) */
  --red: rgba(229, 010, 000, 1.00); /* (#e50000) */

  --txt-light: rgba(112, 112, 112, 1.00); /* (#707070) */
  --txt-dark: rgba(020, 020, 020, 1.00); /* (#141414) */

  --bg-light: rgba(250, 250, 250, 1.00); /* (#fafafa) */
  --bg-gray: rgba(221, 221, 221, 1.00); /* (#e5e5e5) */
  --bg-dark: rgba(022, 022, 024, 1.00); /* (#161618) */

  --border: rgba(221, 221, 221, 1.00); /* (#e5e5e5) */

  --marginfix: 1.00vw;

  #colorPicker {
    display: none;
    background-color: var(--sofa);
  }

  /*	=================================
          Typo
      ================================= */
  margin-top: var(--custom-top-distance);
  container-type: inline-size;
  line-height: 1.2;
}

@font-face {
  font-family: 'sofo-norm';
  src: url('../fonts/oem/OpenSans-Regular.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'sofo-bold';
  src: url('../fonts/oem/OpenSans-Bold.ttf') format('truetype');
  font-weight: 400;
}